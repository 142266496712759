@import "../config";
@import "../mixins/mixins-base";
.prev-next-story-parent {
	@include breakpoint(sm) {
		padding: _em(50) _em(20) _em(15);
	}
}
.prev-next-story {

	.post__item {

		position: relative;

		.description {
			transition: transform 0.3s;
		}

		&:hover { 
			.description {
				transform: translateY(-5px);
			}
		}

		h3 {
			color: $color-white;
			font-size: _em(36);
    		line-height: _em(42, 36);
    		letter-spacing: -0.72px;
    		text-align: center;

    		margin-top: 35px;
			margin-bottom: 35px;

			@include breakpoint(sm) {
				font-size: _em(18);
    			line-height: _em(22, 18);
			}
		}

		.post__details {
			display: flex;
			justify-content: space-between;
			align-items: baseline;
		}

		p {
			@include font-alt();
			font-size: _em(16);
    		line-height: _em(22, 16);
    		text-align: center;

			color: $color-white;
			opacity: 0.9373;

			@include breakpoint(sm) {
				display: none;
			}
		}

		.pagination {
			color: $color-white;
			@include font(bold);
			font-size: _em(14);
			line-height: 60px;
			letter-spacing: 1.4px;
			text-transform: uppercase;
			vertical-align: middle;
			position: relative;
			text-align: center;

			@include breakpoint(sm) {
				line-height: _em(18, 14);
			}
		}

		a {
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			z-index: 1;
		}
	}

	&.prev-story, .wp-block-column:first-child & {
		h3, p, .pagination {
			text-align: left;
		}

		.pagination {
			padding-left: 80px;

			@include breakpoint(sm) {
		       padding-left: 0px;
		       padding-top: 40px;
		    }

			&::after {
	            content: "";
	            background: url('../../../assets/images/btn-arrow-circle.svg') no-repeat top left;
	            background-size: 100%;
	            display: block;
	            width: 60px;
	            height: 60px;
	            position: absolute;
	            left: 0px;
	            top: 50%;
	            transform: translateY(-50%);

	            @include breakpoint(sm) {
			       width: 30px;
			       height: 30px;
			       top: 0;
			       transform: none;
			    }
	        }
		}
	}

	&.next-story, .wp-block-column:last-child & {
		h3, p, .pagination {
			text-align: right;
		}

		.pagination { 
			padding-right: 80px;

			@include breakpoint(sm) {
		       padding-right: 0px;
		       padding-top: 40px;
		    }

			&::after {
	            content: "";
	            background: url('../../../assets/images/btn-arrow-circle.svg') no-repeat top left;
	            background-size: 100%;
	            display: block;
	            width: 60px;
	            height: 60px;
	            position: absolute;
	            right: 0px;
	            top: 50%;
	            transform: rotate(180deg) translateY(50%);

	            @include breakpoint(sm) {
			       width: 30px;
			       height: 30px;
			       top: 0;
			       transform: rotate(180deg);
			    }
	        }
		}
	}

}
