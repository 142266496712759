@media (width <= 768px) {
  .prev-next-story-parent {
    padding: 3.125em 1.25em .9375em;
  }
}

.prev-next-story .post__item {
  position: relative;
}

.prev-next-story .post__item .description {
  transition: -webkit-transform .3s, transform .3s;
}

.prev-next-story .post__item:hover .description {
  -webkit-transform: translateY(-5px);
  transform: translateY(-5px);
}

.prev-next-story .post__item h3 {
  color: #fff;
  letter-spacing: -.72px;
  text-align: center;
  margin-top: 35px;
  margin-bottom: 35px;
  font-size: 2.25em;
  line-height: 1.16667em;
}

@media (width <= 768px) {
  .prev-next-story .post__item h3 {
    font-size: 1.125em;
    line-height: 1.22222em;
  }
}

.prev-next-story .post__item .post__details {
  justify-content: space-between;
  align-items: baseline;
  display: -webkit-flex;
  display: flex;
}

.prev-next-story .post__item p {
  text-align: center;
  color: #fff;
  opacity: .9373;
  font-family: Pitch Medium, sans-serif;
  font-size: 1em;
  font-style: normal;
  font-weight: 600;
  line-height: 1.375em;
}

@media (width <= 768px) {
  .prev-next-story .post__item p {
    display: none;
  }
}

.prev-next-story .post__item .pagination {
  color: #fff;
  letter-spacing: 1.4px;
  text-transform: uppercase;
  vertical-align: middle;
  text-align: center;
  font-family: Eina Bold, sans-serif;
  font-size: .875em;
  font-style: normal;
  font-weight: 700;
  line-height: 60px;
  position: relative;
}

@media (width <= 768px) {
  .prev-next-story .post__item .pagination {
    line-height: 1.28571em;
  }
}

.prev-next-story .post__item a {
  z-index: 1;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.prev-next-story.prev-story h3, .prev-next-story.prev-story p, .prev-next-story.prev-story .pagination, .wp-block-column:first-child .prev-next-story h3, .wp-block-column:first-child .prev-next-story p, .wp-block-column:first-child .prev-next-story .pagination {
  text-align: left;
}

.prev-next-story.prev-story .pagination, .wp-block-column:first-child .prev-next-story .pagination {
  padding-left: 80px;
}

@media (width <= 768px) {
  .prev-next-story.prev-story .pagination, .wp-block-column:first-child .prev-next-story .pagination {
    padding-top: 40px;
    padding-left: 0;
  }
}

.prev-next-story.prev-story .pagination:after, .wp-block-column:first-child .prev-next-story .pagination:after {
  content: "";
  background: url("../../../assets/images/btn-arrow-circle.svg") 0 0 / 100% no-repeat;
  width: 60px;
  height: 60px;
  display: block;
  position: absolute;
  top: 50%;
  left: 0;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

@media (width <= 768px) {
  .prev-next-story.prev-story .pagination:after, .wp-block-column:first-child .prev-next-story .pagination:after {
    width: 30px;
    height: 30px;
    top: 0;
    -webkit-transform: none;
    transform: none;
  }
}

.prev-next-story.next-story h3, .prev-next-story.next-story p, .prev-next-story.next-story .pagination, .wp-block-column:last-child .prev-next-story h3, .wp-block-column:last-child .prev-next-story p, .wp-block-column:last-child .prev-next-story .pagination {
  text-align: right;
}

.prev-next-story.next-story .pagination, .wp-block-column:last-child .prev-next-story .pagination {
  padding-right: 80px;
}

@media (width <= 768px) {
  .prev-next-story.next-story .pagination, .wp-block-column:last-child .prev-next-story .pagination {
    padding-top: 40px;
    padding-right: 0;
  }
}

.prev-next-story.next-story .pagination:after, .wp-block-column:last-child .prev-next-story .pagination:after {
  content: "";
  background: url("../../../assets/images/btn-arrow-circle.svg") 0 0 / 100% no-repeat;
  width: 60px;
  height: 60px;
  display: block;
  position: absolute;
  top: 50%;
  right: 0;
  -webkit-transform: rotate(180deg)translateY(50%);
  transform: rotate(180deg)translateY(50%);
}

@media (width <= 768px) {
  .prev-next-story.next-story .pagination:after, .wp-block-column:last-child .prev-next-story .pagination:after {
    width: 30px;
    height: 30px;
    top: 0;
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }
}
/*# sourceMappingURL=prev-next-story.css.map */
